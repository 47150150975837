<template>
  <div class="org-container">
    <GChart
      :settings="{ packages: ['orgchart'] }"
      :createChart="drawChart"
      type="OrgChart"
      :data="chartData"
      id="chart"
    />
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";

export default {
  name: "OrgChart",

  model: {
    prop: "selectedOptionId",
    event: "change"
  },

  components: { GChart },

  props: {
    options: Array,
    selectedOptionId: String,
    topNode: String
  },

  data() {
    return {
      chartData: null,
      google: null,
      chart: null
    };
  },

  methods: {
    drawChart(el, google) {
      this.el = el;
      this.google = google;
      this.chart = new google.visualization.OrgChart(el);
      this.setData();
      google.visualization.events.addListener(
        this.chart,
        "select",
        this.handleSelect
      );
    },

    handleSelect() {
      let selected = this.chart.getSelection()[0];
      if (!selected) {
        this.$emit("change", "");
        return;
      }
      const value = this.chartData.getValue(selected.row, selected.column || 0);

      if (value === "topNode") {
        this.$emit("change", "");
        return;
      }

      this.$emit("change", value);
    },

    setData() {
      this.chartData = new this.google.visualization.DataTable();
      this.chartData.addColumn("string", "Name");
      this.chartData.addColumn("string", "Manager");
      this.chartData.addColumn("string", "ToolTip");
      this.chartData.addRow([
        { v: "topNode", f: this.topNode },
        "",
        this.topNode
      ]);
      this.options.forEach(option => {
        this.chartData.addRow([
          {
            v: option.id,
            f: option.name || option.value
          },
          option.parentId || "topNode",
          option.name || option.value
        ]);
      });
      this.chart.draw(this.chartData, {
        allowHtml: true,
        allowCollapse: true,
        nodeClass: "orgChart",
        selectedNodeClass: "selected"
      });
    }
  },

  watch: {
    options: {
      handler() {
        this.setData();
      },
      deep: true
    }
  }
};
</script>

<style>
.org-container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: auto;
  padding: var(--lengthMd1);
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm1);
  overflow-x: auto;
  width: 100%;
}

.org-container .orgChart {
  padding: var(--lengthMd1);
  color: var(--fontColor1);
  font-size: 12px;
  font-weight: var(--medium);
  background-color: var(--grayColor1);
  border: 1px solid var(--grayColor2);
  border-radius: 5px;
  transition: background-color 300ms;
  cursor: pointer;
  user-select: none;
  max-width: 100px;
  min-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.org-container .selected {
  color: white;
  background-color: var(--mainColor1);
  border-color: var(--mainColor1);
}

#chart .google-visualization-orgchart-linebottom {
  border-bottom: 2px solid var(--grayColor2);
}

#chart .google-visualization-orgchart-lineleft {
  border-left: 2px solid var(--grayColor2);
}

#chart .google-visualization-orgchart-lineright {
  border-right: 2px solid var(--grayColor2);
}

#chart .google-visualization-orgchart-linetop {
  border-top: 2px solid var(--grayColor2);
}
</style>
