<template>
  <div>
    <view-hero>
      <template slot="title">Jerarquía</template>
      <template slot="description">
        Puedes organizar tu encuesta de clima laboral de acuerdo a la estructura
        de la empresa. De esta forma, tendrás resultados a la medida en tu
        organización.
      </template>
    </view-hero>

    <div class="header">
      <custom-button variant="primary" @click="openAddLevelModal">
        Agregar Nivel
      </custom-button>

      <custom-button
        v-if="levels && levels.length"
        variant="secondary"
        @click="addOptionsModal = true"
      >
        Agregar Opciones
      </custom-button>

      <custom-button
        v-if="selectedOptionId"
        variant="secondary"
        @click="openEditOptionModal"
      >
        <font-awesome-icon icon="pen" slot="icon" />
        Editar Opción
      </custom-button>

      <custom-button
        v-if="selectedOptionId"
        variant="danger"
        @click="onOptionDelete"
      >
        <font-awesome-icon icon="trash-alt" slot="icon" />
        Eliminar Opción
      </custom-button>

      <custom-button
        v-if="selectedLevelId"
        variant="danger"
        @click="onLevelDelete"
      >
        <font-awesome-icon icon="trash-alt" slot="icon" />
        Eliminar Nivel
      </custom-button>
    </div>

    <custom-section>
      <template slot="title">Estructura Organizacional</template>

      <org-chart
        :options="options"
        topNode="Organización"
        v-model="selectedOptionId"
      />

      <org-chart
        :options="levels"
        topNode="Niveles"
        v-model="selectedLevelId"
      />
    </custom-section>

    <add-level-modal ref="addLevelModal" />

    <edit-option-modal ref="editOptionModal" />

    <modal :isOpen="addOptionsModal" @close="addOptionsModal = false">
      <template slot="title">Agregar Opciones</template>
      <add-multiple-options @close="addOptionsModal = false" />
    </modal>

    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import CustomButton from "@/components/CustomButton.vue";
import Modal from "@/components/Modal.vue";
import AddMultipleOptions from "./AddMultipleOptions.vue";
import OrgChart from "@/components/OrgChart.vue";
import CustomSection from "@/components/CustomSection.vue";
import AddLevelModal from "./AddLevelModal.vue";
import EditOptionModal from "./EditOptionModal.vue";
import ConfirmDialogue from "../../components/ConfirmDialogue.vue";
import ViewHero from "../../components/ViewHero.vue";

export default {
  components: {
    CustomButton,
    Modal,
    AddMultipleOptions,
    CustomSection,
    OrgChart,
    AddLevelModal,
    EditOptionModal,
    ConfirmDialogue,
    ViewHero
  },

  data: () => ({
    addOptionsModal: false,
    selectedOptionId: "",
    selectedLevelId: ""
  }),

  computed: {
    ...mapState("hierarchy", ["levels", "options"]),

    ...mapState(["survey"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("hierarchy", [
      "fetchHierarchy",
      "deleteLevel",
      "deleteOption"
    ]),

    openAddLevelModal() {
      this.$refs.addLevelModal.openModal();
    },

    openEditOptionModal() {
      const { id, name, levelId, parentId } = this.options.find(
        o => o.id === this.selectedOptionId
      );

      this.$refs.editOptionModal.openModal({
        id,
        name,
        levelId,
        parentId
      });
    },

    async onOptionDelete() {
      const hasDependencies = this.options.some(
        o => o.parentId === this.selectedOptionId
      );

      if (hasDependencies) {
        this.setAlert({
          state: "error",
          message: `Esta opción contiene dependencias, cambie o elimínelas antes de eliminarla.`
        });

        return;
      }

      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Opción",
        message:
          "¿Estás seguro que quieres eliminar esta opción? Una vez eliminada no podrás recuperarla.",
        isDestructive: true,
        okButton: "Sí, estoy seguro",
        cancelButton: "No, volver"
      });

      if (ok) {
        try {
          await this.deleteOption(this.selectedOptionId);

          this.setAlert({
            state: "success",
            message: "Se eliminó la opción"
          });

          this.selectedOptionId = "";
        } catch (err) {
          console.log(err);

          this.setAlert({
            state: "error",
            message: "Ocurrió un error, por favor vuelva a intentarlo"
          });
        }
      }
    },

    hasDependencies(levelId) {
      return this.levels.find(({ parentId }) => parentId === levelId);
    },

    async onLevelDelete() {
      if (this.hasDependencies(this.selectedLevelId)) {
        this.setAlert({
          state: "error",
          message:
            "Otros niveles dependen de este, por favor eliminelos primero"
        });
        return;
      }

      const ok = await this.$refs.confirmDialogue.show({
        title: "Eliminar Nivel",
        message:
          "¿Estás seguro que quieres eliminar este nivel? Se eliminarán todas las opciones de este nivel y una vez borrado no podrás recuperarlo.",
        isDestructive: true,
        okButton: "Sí, estoy seguro",
        cancelButton: "No, volver"
      });

      if (ok) {
        try {
          await this.deleteLevel(this.selectedLevelId);

          this.setAlert({
            state: "success",
            message: "Se eliminó el nivel"
          });

          this.selectedLevelId = "";
        } catch (err) {
          this.setAlert({
            state: "error",
            message: "Ocurrió un error, por favor vuelva a intentarlo"
          });
        }
      }
    }
  },

  async mounted() {
    try {
      await this.fetchHierarchy();
    } catch (err) {
      console.log(err);
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.survey.active) next("/");
      else next();
    });
  }
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  padding: var(--lengthSm3);
  background-color: white;
  border: 1px solid var(--grayColor2);
  border-radius: var(--lengthSm2);
}
</style>
