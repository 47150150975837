<template>
  <multi-step-form
    v-model="currentStep"
    :steps="steps"
    submitButtonText="Agregar"
    @submit="onSubmit"
    @cancel="$emit('close')"
  >
    <template v-if="currentStep === 0">
      <custom-select
        v-model="levelId"
        :options="levels"
        label="Nivel"
        rules="required"
      />

      <custom-select
        v-model="parentId"
        :options="getParentOptions(levelId)"
        label="Opción Superior"
        :rules="getParentOptions(levelId).length ? 'required' : ''"
      />
    </template>

    <template v-if="currentStep === 1">
      <div class="btn-container">
        <custom-button variant="secondary" type="button" @click="addOption">
          <font-awesome-icon icon="plus" slot="icon" />
          Agregar opción
        </custom-button>

        <file-uploader @input="onFileUpload" />
      </div>

      <div v-for="(option, index) in newOptions" :key="index" class="option">
        <custom-input
          v-model="option.name"
          :label="`Opción ${index + 1}`"
          :rules="
            `required|minmax:0,50|excluded:
            ${options.map(o => o.name)},
            ${newOptions.map((o, i) => (i !== index ? o.name : null))}`
          "
        />
        <custom-button
          variant="secondary"
          type="button"
          @click="deleteOption(index)"
        >
          <font-awesome-icon icon="times" slot="icon" />
        </custom-button>
      </div>
    </template>
  </multi-step-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import Papa from "papaparse";

import CustomButton from "@/components/CustomButton.vue";
import CustomInput from "@/components/CustomInput.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import FileUploader from "@/components/FileUploader.vue";
import MultiStepForm from "../../components/MultiStepForm.vue";

export default {
  name: "AddMultipleOptions",

  components: {
    CustomButton,
    CustomInput,
    CustomSelect,
    FileUploader,
    MultiStepForm
  },

  data: () => ({
    levelId: "",
    parentId: "",
    newOptions: [],

    currentStep: 0,
    steps: ["Elegir Nivel", "Agregar Opciones"]
  }),

  computed: {
    ...mapState("hierarchy", ["levels", "options"]),

    ...mapGetters("hierarchy", ["getLevelOptions"])
  },

  methods: {
    ...mapMutations(["setAlert"]),

    ...mapActions("hierarchy", ["addOptions"]),

    onFileUpload(file) {
      if (!file) return;

      Papa.parse(file, {
        skipEmptyLines: true,
        complete: results => {
          if (!results) return;

          const options = results.data.map(option => ({ name: option[0] }));

          this.newOptions = options;
        }
      });
    },

    addOption(name = "") {
      this.newOptions.push({ name });
    },

    deleteOption(index) {
      if (this.newOptions.length <= 1) {
        this.setAlert({
          state: "error",
          message: "Debe agregar por lo menos una opción"
        });

        return;
      }

      this.newOptions.splice(index, 1);
    },

    getParentOptions(levelId) {
      const currentLevel = this.levels.find(level => level.id === levelId);

      if (!currentLevel || currentLevel.level === 0) return [];

      const parentLevel = this.levels.find(
        ({ level }) => level === currentLevel.level - 1
      );

      return parentLevel ? this.getLevelOptions(parentLevel.id) : [];
    },

    async onSubmit() {
      const options = this.newOptions.map(option =>
        this.parentId
          ? {
              levelId: this.levelId,
              parentId: this.parentId,
              name: option.name
            }
          : {
              levelId: this.levelId,
              name: option.name
            }
      );

      try {
        await this.addOptions(options);

        this.setAlert({
          state: "success",
          message: "Opciones agregadas"
        });

        this.$emit("close");
      } catch (err) {
        console.log(err);

        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor intente nuevamente"
        });
      }
    }
  },

  mounted() {
    this.addOption();
  }
};
</script>

<style scoped>
.btn-container {
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
}

.option {
  display: flex;
  grid-gap: var(--lengthSm1);
  gap: var(--lengthSm1);
}

.option .input {
  flex-grow: 1;
}
</style>
