<template>
  <ValidationProvider
    tag="label"
    :rules="!options.length ? '' : rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="select"
  >
    <select
      @input="$emit('change', $event.target.value)"
      class="field"
      :tabindex="tabindex"
      :disabled="!options.length"
      :value="selected || ''"
    >
      <option disabled :value="''" :style="optionStyles">
        Elija una opción
      </option>

      <option
        v-for="option of options"
        :key="option.id || option.value"
        :value="typeof option === 'object' ? option.id || option.value : option"
        :style="optionStyles"
      >
        {{ typeof option === "object" ? option.value || option.name : option }}
      </option>
    </select>

    <span
      v-if="label"
      class="label"
      :class="{ 'label--invalid': errors.length }"
    >
      <span>{{ label || name }}</span>
      <span>{{ required ? " *" : "" }}</span>
    </span>

    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "CustomSelect",

  model: {
    prop: "selected",
    event: "change"
  },

  components: { ValidationProvider },

  props: {
    selected: {
      default: "",
      type: String
    },

    rules: {
      default: "",
      type: [String, Object]
    },

    name: {
      type: String,
      default: ""
    },

    label: String,

    options: {
      type: Array,
      required: true
    },

    tabindex: {
      type: Number,
      required: false,
      default: 0
    }
  },

  watch: {
    options(value) {
      const opts =
        typeof value[0] === "object"
          ? value.reduce((a, o) => a.concat([o.id || o.value]), [])
          : value;
      if (!opts.includes(this.selected)) this.$emit("change", "");
    }
  },

  computed: {
    optionStyles: () => ({
      color: "var(--fontColor1)",
      fontFamily: "var(--inter)"
    }),

    isSelected() {
      return option =>
        typeof option === "object"
          ? this.selected === (option.id || option.value)
          : this.selected === option;
    }
  }
};
</script>

<style scoped>
.select {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: var(--lengthMd1);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--mainColor1);
}

.label--invalid,
.error {
  color: var(--dangerColor);
}

.error {
  margin-top: var(--lengthSm1);
  font-size: 0.75rem;
}

.field {
  width: 100%;
  padding: var(--lengthMd1) var(--lengthSm2) var(--lengthSm3);
  color: var(--fontColor1);
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
  cursor: pointer;
  transition: border-color 300ms;
}

.field:not(.field--invalid):focus {
  border-color: var(--mainColor1);
}

.field--invalid {
  border-color: var(--dangerColor);
}
</style>
