<template>
  <modal-2 ref="modal">
    <template slot="title">Nuevo Nivel</template>

    <custom-form @submit="onSubmit">
      <custom-input
        v-model="name"
        label="Nombre"
        :rules="`required|minmax:0,50|excluded:${levels.map(l => l.name)}`"
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="closeModal">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary">
          Agregar
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomForm from "../../components/CustomForm.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomInput from "../../components/CustomInput.vue";
import Modal2 from "../../components/Modal2.vue";

export default {
  name: "AddLevelModal",

  components: { Modal2, CustomForm, CustomInput, CustomButton },

  data: () => ({
    name: ""
  }),

  computed: {
    ...mapState("hierarchy", ["levels"]),

    lastLevel() {
      return this.levels.reduce(
        (last, { id, level }) => (level > last.level ? { level, id } : last),
        { id: "", level: 0 }
      );
    }
  },

  methods: {
    ...mapActions("hierarchy", ["addLevel"]),

    ...mapMutations(["setAlert"]),

    openModal() {
      this.$refs.modal.open();
    },

    closeModal() {
      this.name = "";
      this.$refs.modal.close();
    },

    async onSubmit() {
      try {
        console.log(this.lastLevel);

        await this.addLevel({
          name: this.name,
          level: this.lastLevel.level + 1,
          parentId: this.lastLevel.id
        });

        this.setAlert({
          state: "success",
          message: `Se agregó el nivel ${this.name}`
        });

        this.closeModal();
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor vuelva a intentarlo"
        });

        console.log(err);
      }
    }
  }
};
</script>

<style></style>
