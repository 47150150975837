<template>
  <modal-2 ref="modal">
    <template slot="title">Editar Opción</template>
    <custom-form @submit="onSubmit">
      <custom-select
        v-model="parentId"
        label="Opción Superior"
        :options="parentOptions"
        :rules="parentOptions.length ? 'required' : ''"
      />

      <custom-input
        v-model="name"
        label="Nombre"
        :rules="
          `required|minmax:0,50|excluded:${options
            .filter(o => o.id !== id)
            .map(o => o.name)}`
        "
      />

      <template slot="buttons">
        <custom-button type="button" variant="secondary" @click="closeModal">
          Cancelar
        </custom-button>
        <custom-button type="submit" variant="primary">
          Guardar
        </custom-button>
      </template>
    </custom-form>
  </modal-2>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomForm from "../../components/CustomForm.vue";
import CustomButton from "../../components/CustomButton.vue";
import CustomInput from "../../components/CustomInput.vue";
import CustomSelect from "../../components/CustomSelect.vue";
import Modal2 from "../../components/Modal2.vue";

export default {
  name: "EditOptionModal",

  components: { Modal2, CustomForm, CustomSelect, CustomInput, CustomButton },

  data: () => ({
    id: "",
    name: "",
    levelId: "",
    parentId: ""
  }),

  computed: {
    ...mapState("hierarchy", ["levels", "options"]),

    parentOptions() {
      const currentLevel = this.levels.find(l => l.id === this.levelId);

      if (!currentLevel || !currentLevel.parentId) return [];

      return this.options.filter(o => o.levelId === currentLevel.parentId);
    }
  },

  methods: {
    ...mapActions("hierarchy", ["editOption"]),

    ...mapMutations(["setAlert"]),

    closeModal() {
      this.id = "";
      this.name = "";
      this.levelId = "";
      this.parentId = "";

      this.$refs.modal.close();
    },

    openModal({ id, name, levelId, parentId }) {
      this.id = id;
      this.name = name;
      this.levelId = levelId;
      this.parentId = parentId;

      this.$refs.modal.open();
    },

    async onSubmit() {
      try {
        await this.editOption({
          id: this.id,
          name: this.name,
          levelId: this.levelId,
          parentId: this.parentId
        });

        this.closeModal();

        this.setAlert({
          state: "success",
          message: "Se editó la opción"
        });
      } catch (err) {
        this.setAlert({
          state: "error",
          message: "Ocurrió un error, por favor intente nuevamente"
        });
      }
    }
  }
};
</script>
